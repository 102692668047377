<template>
  <div>
    <div class="tab-bar">
      <div
        v-for="item in tabBarList"
        :key="item.id"
        :class="{ active: queryParams.state === item.id }"
        @click="changeTab(item.id)"
      >
        <span>
          {{ item.name }}
          <span class="dot" v-if="item.showDot"></span>
        </span>
      </div>
    </div>

    <div class="filter-panel">
      <CSSelect style="margin-right: 0">
        <el-date-picker
          v-model="queryParams.startTime"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          placeholder="请选择开始时间"
          prefix-icon="el-icon-time"
          :editable="false"
        ></el-date-picker>
      </CSSelect>
      <span class="inline-gray"></span>
      <CSSelect>
        <el-date-picker
          v-model="queryParams.endTime"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          placeholder="请选择结束时间"
          prefix-icon="el-icon-time"
          :editable="false"
        ></el-date-picker>
      </CSSelect>
      <CSSelect style="width: 200px; padding-left: 5px">
        <select v-model="queryParams.handlerId">
          <option value="">全部处理人</option>
          <option v-for="staff in staffList" :key="staff.id" :value="staff.id">
            {{ staff.name }}
          </option>
        </select>
      </CSSelect>
      <input
        type="text"
        class="cs-input"
        placeholder="搜索用户信息"
        v-model="queryParams.userInfo"
        style="margin-right: 30px"
      />
      <button class="btn btn-primary" @click="queryFinanceList()">查询</button>
    </div>
    <div class="result-panel">
      <CSTable :thead-top="filterHeight">
        <template v-slot:header>
          <div class="table-header-panel">
            <p v-if="queryParams.state === 1">
              累计已付金额：{{ responseData.totalPaid || "-" }}元
            </p>
            <p v-if="queryParams.state === 3">
              累计已退金额：{{ responseData.totalRefund || "-" }}元
            </p>
          </div>
        </template>
        <template v-slot:thead>
          <tr v-if="queryParams.state === 3">
            <th>退款时间</th>
            <th>类型</th>
            <th>应付金额</th>
            <th>已付金额</th>
            <th>已退金额</th>
            <th>退款原因</th>
            <th>退款人</th>
            <th>用户信息</th>
            <th>处理人</th>
            <th>支付渠道</th>
            <th>备注</th>
          </tr>
          <tr v-if="queryParams.state === 1">
            <th>付款时间</th>
            <th>类型</th>
            <th>应付金额</th>
            <th>已付金额</th>
            <th>用户信息</th>
            <th>处理人</th>
            <th>支付渠道</th>
            <th>备注</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <template v-if="queryParams.state === 3">
            <template v-if="responseData.data && responseData.data.length > 0">
              <tr v-for="item in responseData.data" :key="item.id">
                <td class="date-td">{{ item.time }}</td>
                <td>{{ ticketType[item.type] }}</td>
                <td>{{ item.payableMoney | formatMoney }}</td>
                <td>{{ item.actuallyMoney | formatMoney }}</td>
                <td>{{ item.refundMoney | formatMoney }}</td>
                <td>
                  <template v-if="item.refundCause">
                    <p
                      class="allow-click"
                      @click="
                        redundMoneyDialog = {
                          visible: true,
                          data: {
                            payableMoney: item.payableMoney,
                            refundMoney: item.refundMoney,
                            refundCause: item.refundCause,
                            refundHandler: item.refundHandler,
                          },
                        }
                      "
                    >
                      查看
                    </p>
                  </template>
                  <template v-else>-</template>
                </td>
                <td>{{ item.refundHandler || "-" }}</td>
                <td>
                  <p>{{ item.consumerName }}</p>
                  <p>{{ item.consumerPhone }}</p>
                  <p>{{ item.consumerAddr }}</p>
                </td>
                <td>
                  {{ item.handler }}
                </td>
                <td>
                  <p>{{ payChannel[item.payChannel] }}</p>
                  <p>{{ item.orderNumber }}</p>
                  <!-- item.voucher -->
                  <p
                    v-if="item.voucher && item.voucher.length > 0"
                    @click="lookVoucher(item.voucher)"
                    class="allow-click"
                  >
                    现金凭证
                  </p>
                </td>
                <td>
                  <div
                    v-if="item.comment"
                    class="allow-click"
                    @click="
                      commentDialog = {
                        visible: true,
                        data: {
                          comment: item.comment,
                        },
                      }
                    "
                  >
                    查看
                  </div>
                  <template v-else>-</template>
                </td>
              </tr>
            </template>
          </template>
          <template v-if="(responseData.data && responseData.data.length == 0)||JSON.stringify(responseData) == '{}'">
            <tr v-if="isLoading">
              <td :colspan="12" class="wrapper-loading">
                <div style="text-align: center">加载中</div>
                <div class="loading loading1"></div>
                <div class="loading loading2"></div>
                <div class="loading loading3"></div>
                <div class="loading loading4"></div>
                <div class="loading loading5"></div>
              </td>
            </tr>
            <tr v-else>
              <td :colspan="12">
                <div style="text-align: center">暂无数据</div>
              </td>
            </tr>
          </template>
          <template v-if="queryParams.state === 1">
            <template v-if="responseData.data && responseData.data.length > 0">
              <tr v-for="item in responseData.data" :key="item.id">
                <td class="date-td">{{ item.time }}</td>
                <td>{{ ticketType[item.type] }}</td>
                <td>{{ item.payableMoney | formatMoney }}</td>
                <td>{{ item.actuallyMoney | formatMoney }}</td>
                <td>
                  <p>{{ item.consumerName }}</p>
                  <p>{{ item.consumerPhone }}</p>
                  <p>{{ item.consumerAddr }}</p>
                </td>
                <td>
                  {{ item.handler }}
                </td>
                <td>
                  <p>{{ payChannel[item.payChannel] }}</p>
                  <p>{{ item.orderNumber }}</p>
                  <p
                    v-if="item.voucher && item.voucher.length > 0"
                    @click="lookVoucher(item.voucher)"
                    class="allow-click"
                  >
                    现金凭证
                  </p>
                </td>
                <td>
                  <div
                    v-if="item.comment"
                    class="allow-click"
                    @click="
                      commentDialog = {
                        visible: true,
                        data: {
                          comment: item.comment,
                        },
                      }
                    "
                  >
                    查看
                  </div>
                  <template v-else>-</template>
                </td>
              </tr>
            </template>
          </template>
        </template>
      </CSTable>
      <Pagination />
    </div>

    <CSDialog
      dialogTitle="退款"
      :dialogVisible="redundMoneyDialog.visible"
      dialogWidth="580px"
      dialog-header-class="alert-bg"
      :dialogShowConfirmBtn="false"
      dialogCancelBtnText="关闭"
      @onClose="redundMoneyDialog.visible = false"
    >
      <div slot="dialog-content" style="padding: 20px 30px">
        <div v-if="redundMoneyDialog.data">
          <div class="dialog-item">
            <div class="dialog-item-label">退款人</div>
            {{ redundMoneyDialog.data.refundHandler }}
          </div>
          <div class="dialog-item">
            <div class="dialog-item-label">已付金额</div>
            {{ redundMoneyDialog.data.payableMoney | formatMoney }}
          </div>
          <div class="dialog-item">
            <div class="dialog-item-label">已退金额</div>
            {{ redundMoneyDialog.data.refundMoney | formatMoney }}
          </div>
          <div class="dialog-item">
            <div class="dialog-item-label">退款原因</div>
            {{ redundMoneyDialog.data.refundCause }}
          </div>
        </div>
      </div>
    </CSDialog>

    <CSDialog
      dialogTitle="备注"
      dialogWidth="580px"
      dialog-header-class="alert-bg"
      :dialogVisible="commentDialog.visible"
      dialog-cancel-btn-text="关闭"
      @onClose="commentDialog.visible = false"
      :dialog-show-confirm-btn="false"
    >
      <div
        slot="dialog-content"
        style="padding: 20px 30px; font-size: 24px; text-align: center"
      >
        <span v-if="commentDialog.data">
          {{ commentDialog.data.comment }}
        </span>
        <span v-else> - </span>
      </div>
    </CSDialog>

    <ViewImageModal />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination.vue";
import CSSelect from "@/components/common/CSSelect";
import ViewImageModal from "@/components/ViewImageModal";
import CSDialog from "@/components/common/CSDialog";
import {
  financeQueryUrl,
  queryDepartmentStaffUrl,
  queryDepartmentUrl,
} from "@/requestUrl";
import dayjs from "dayjs";
import { ORG_LEVEL } from "@/constant/index";
import { RED_DOT_TYPES } from "../../common/commonConst.js";
import { getShowRedDot } from "@/utils/index.js";
import CSTable from "@/components/common/CSTable";

export default {
  props: {},
  components: {
    CSTable,
    Pagination,
    CSSelect,
    CSDialog,
    ViewImageModal,
  },
  data() {
    return {
      isLoading:true,
      filterHeight: 0,
      commentDialog: {
        visible: false,
      },
      redundMoneyDialog: {
        visible: false,
      },
      tabBarList: [
        { id: 1, name: "付款", showDot: false },
        { id: 3, name: "已退款", showDot: false },
      ],
      ticketType: {
        0: "上门维修",
        1: "气窗开启",
      },
      payChannel: {
        0: "微信",
        1: "支付宝",
        2: "现金支付",
        3: "银行转账",
      },
      queryParams: {
        state: 1,
        startTime: dayjs().subtract(1, "month").format("YYYY-MM-DD"),
        endTime: dayjs().format("YYYY-MM-DD"),
        userInfo: "",
        handlerId: "",
        regionId: this.$vc.getCurrentRegion().code,
      },
      responseData: {

      },
      staffList: [],
    };
  },
  filters: {
    formatMoney(val) {
      return val ? val + "元" : "-";
    },
  },
  created() {
    window.addEventListener("keydown", this.queryFinanceListDown);
    this.responseData.data =[];
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.queryFinanceListDown);
  },
  deactivated(){
     window.removeEventListener("keydown", this.queryFinanceListDown);
  },
  async mounted() {
    const departmentList = await this.queryDepartmentList(
      ORG_LEVEL.DEPARTMENT,
      1,
      100,
      this.$vc.getCurrentStaffInfo().companyCode
    );
    let departmentCode = "";
    departmentList.forEach((department) => {
      if (department.dutyType == 1003) {
        departmentCode = department.code;
      }
    });
    this.getStaffList(departmentCode);
    this.queryFinanceList();
    this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
      this.queryFinanceList(pageNo);
    });
    this.$vc.emit(this.$route.path, "dot", "ticakeBillTabar", (params) => {
      this.$set(this.tabBarList[params.index], "showDot", res.data.isShow);
    });
    this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    window.addEventListener("resize", () => {
      this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    });
  },
  methods: {
    queryFinanceListDown(e) {
      if (e.keyCode == 13) {
        this.queryFinanceList();
      }
    },
    /**
     * 查询公司/部门接口
     * @param {Number} level 0:代表公司
     * @param {NUmber} pageNo 分页页数
     * @param {Number} pageSize 每次查看的数量
     * @param {Number | String} parentCode 公司code
     *  */
    queryDepartmentList(
      level = ORG_LEVEL.COMPANY,
      pageNo = 1,
      pageSize = 100,
      parentCode
    ) {
      return this.$fly
        .post(queryDepartmentUrl, {
          regionCode: this.$vc.getCurrentRegion().code,
          code: "",
          name: "",
          dutyType: "",
          parentCode,
          level,
          pageNo,
          pageSize: level == ORG_LEVEL.COMPANY ? 100 : pageSize,
        })
        .then((res) => {
          if (res.code !== 0) {
            return;
          }

          return res.data;
        });
    },
    // 获取员工列表
    getStaffList(departmentCode) {
      this.$fly
        .post(queryDepartmentStaffUrl, {
          regionCode: this.$vc.getCurrentRegion().code || "",
          departmentCode,
          jobLevel: "",
        })
        .then((res) => {
          if (res.code !== 0) {
            return;
          }
          this.staffList = res.data;
        });
    },
    /**
     * 查看凭证
     * @param {String | Array} vouchers) 凭证地址
     *  */
    lookVoucher(vouchers) {
      this.$vc.emit(this.$route.path, "viewImageModal", "view", {
        title: "现金凭证",
        photos: vouchers,
      });
    },
    changeTab(state) {
      this.queryParams.state = state;
      this.isLoading = true;
      this.queryFinanceList();
    },
    queryFinanceList(pageNo = 1, pageSize = 10) {
      this.$fly
        .post(financeQueryUrl, {
          ...this.queryParams,
          pageNo,
          pageSize,
        })
        .then((res) => {
          if (res.code !== 0) {
            return;
          }
          if (pageNo === 1) {
            this.$vc.emit(this.$route.path, "pagination", "init", {
              total: res.data.total,
              currentPage: pageNo,
              pageSize,
            });
          }
          if (this.queryParams.state == 1) {
            getShowRedDot(this, RED_DOT_TYPES.TIC_BILL_PAYMENT);
          } else {
            getShowRedDot(this, RED_DOT_TYPES.TIC_BILL_REFUND);
          }
          this.isLoading = false;
          this.responseData = res.data;
        });
    },
  },
};
</script>

<style lang="stylus" scoped>
.dialog-item {
  color: #000;
  font-size: 24px;

  &:not(:last-of-type) {
    margin-bottom: 22px;
  }

  &-label {
    width: 110px;
    text-align: right;
    margin-right: 40px;
  }

  div {
    display: inline-block;
  }
}

.dialog-footer {
  position: relative;

  &::before {
    position: absolute;
    content: ' ';
    top: -20px;
    left: -20px;
    width: 510px;
    height: 1px;
    background: #ddd;
  }

  .btn-cancel {
    width: 80px;
    line-height: 40px;
    padding: 0;
    border-radius: 6px;
    font-size: 24px;
  }
}

.loading {
  animation: identifier 2s linear 0s infinite;
  width: 1px;
  background-color: black;
  height: 15px;
  margin-left: 5px;
}

.loading2 {
  animation: identifier 2s linear 0.1s infinite;
}

.loading3 {
  animation: identifier 2s linear 0.2s infinite;
}

.loading4 {
  animation: identifier 2s linear 0.3s infinite;
}

.loading5 {
  animation: identifier 2s linear 0.4s infinite;
}

.wrapper-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes identifier {
  0% {
    height: 15px;
  }

  25% {
    height: 0px;
  }

  50% {
    height: 15px;
  }

  75% {
    height: 0px;
  }

  100% {
    height: 15px;
  }
}
</style>
